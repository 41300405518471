import classNames from 'classnames'
import Spinner from 'app/components/Spinner'

export type ButtonProps = {
  className?: string
  label?: string
  size?: `xs` | `sm` | `md` | `lg` | `xl`
  mode: `primary` | `secondary` | `white` | `tertiary` | `danger`
  type?: `button` | `submit` | `reset`
  disabled?: boolean
  onClick?: (evt?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children?: React.ReactNode
  loading?: boolean
  form?: string
}

export default function Button({
  className,
  label,
  size = `md`,
  mode,
  form,
  type = `button`,
  disabled = false,
  loading = false,
  onClick,
  children,
}: ButtonProps) {
  const btnClass = classNames({
    btn: true,
    'btn btn-danger': mode === `danger`,
    'btn-tertiary': mode === `tertiary` || mode === `white`,
    'btn-sm': size === `sm` || size === `xs`,
    'btn-md': size === `md`,
    'btn-lg': size === `lg` || size === `xl`,
    'btn-primary': mode === `primary`,
    'btn-secondary': mode === `secondary`,
    'btn-danger': mode === `danger`,
    [className || ``]: className != null,
  })

  return (
    <>
      <button type={type} form={form} className={btnClass} onClick={onClick} disabled={disabled || loading}>
        {loading ? <Spinner /> : label}
        {children}
      </button>
    </>
  )
}
